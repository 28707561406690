export default {
    open: 'Ανοίγεις',
    close: 'Κλείσε',
    apply: 'Ισχύουν',
    cancel: 'Ματαίωση',
    search: 'Κοντά',
    cancel_search: 'Ακύρωση αναζήτησης',
    edit_search: 'Επεξεργασία αναζήτησης',
    erase: 'Ματαίωση',
    back_to_results: 'Επιστρέψτε στα αποτελέσματα',
    save: 'Αποθηκεύσετε',
    retry: 'Προσπάθησε ξανά',
    click_here: 'Κάντε κλικ ΕΔΩ',
    copy: 'αντίγραφο',
    copied: 'Αντιγράφηκε',
    copied_coordinates: 'Οι συντεταγμένες αντιγράφηκαν',
    select: 'Επιλέγω',
    selected: 'Επιλεγμένο',
    selected_plural: 'Επιλεγμένο',
    deselect: 'Αποεπιλογή',
    select_multi_vehicles: 'Επιλέξτε ένα ή περισσότερα οχήματα',
    include: 'Περιλαμβάνω',
    exclude: 'Αποκλείω',
    balanced: 'Ισορροπημένο',
    input: 'Είσοδος',
    output: 'Εξοδος',
    verified: 'Επαληθεύτηκε',
    empty_search: 'Δεν βρέθηκαν αποτελέσματα',
    read_more: 'Διαβάστε περισσότερα',
    details: 'Λεπτομέριες',
    actual: 'Ρεύμα',
    residual_time: 'Χρόνος που απομένει',
    next: 'Επόμενο',
    previous: 'Προηγούμενος',
    menu_open: 'Άνοιγμα μενού',
    hide: 'Κρύβω',
    show: 'προβολή',
    none: 'Κανείς',
    none_alt: 'Κανένας',
    all: 'Ολοι',
    all_alt: 'Ολα',
    default: 'Προκαθορισμένο',
    settings: 'Ρυθμίσεις',
    reset_settings: 'Επαναφορά ρυθμίσεων',
    reset_settings_success: 'Οι ρυθμίσεις επαναφέρθηκαν με επιτυχία',
    multiselect_default_value: {
        singular: 'Επιλεγμένη τιμή:',
        plural: 'Επιλεγμένες τιμές:'
    },
    available: 'Διαθέσιμος',
    unavailable: 'Μη διαθέσιμος',
    not_available: 'Μη διαθέσιμος',
    not_available_plural: 'Μη διαθέσιμος',
    from: 'από το',
    to: 'στο',
    of: 'Του',
    yes: 'Ναί',
    no: 'Οχι',
    minutes: 'Λεπτά',
    hours: 'Ωρες',
    hour: 'Τώρα',
    days: 'Ημέρες',
    day: 'Ημέρα',
    months: 'Μήνες',
    month: 'Μήνας',
    years: 'Χρόνια',
    year: 'Ετος',
    days_names: ['Κυριακή', 'Δευτέρα', 'Τρίτη', 'Τετάρτη', 'Πέμπτη', 'Παρασκευή', 'Σάββατο'],
    days_names_short: ['Ήλιος', 'Δευτ', 'Αφανίζω', 'Νυμφεύομαι', 'Πέμ', 'Παρ', 'Σάβ'],
    months_names: [
        'Ιανουάριος',
        'Φεβρουάριος',
        'Μάρτιος',
        'Απρίλιος',
        'Ενδέχεται',
        'Ιούνιος',
        'Ιούλιος',
        'Αύγουστος',
        'Σεπτέμβριος',
        'Οκτώβριος',
        'Νοέμβριος',
        'Δεκέμβριος'
    ],
    months_names_short: [
        'Γεν',
        'Φεβ',
        'Αφανίζω',
        'Απρ',
        'Mag',
        'Παρακάτω',
        'Ιούλ',
        'Αύγ',
        'Σειρά',
        'Οκτ',
        'Νοε',
        'Δεκ'
    ],
    areas: 'Περιοχές',
    area: 'Περιοχή',
    time: 'Χρόνος (λεπτά)',
    time_label: 'χρόνος',
    cost: 'Κόστος',
    toll_cost: 'Κόστος διοδίων',
    km_cost: 'Κόστος ανά χλμ',
    hourly_cost: 'Κόστος ώρας',
    working_cost: 'Συνολικό κόστος ανθρώπου',
    total_cost: 'Συνολικό κόστος',
    expire_date: 'Ημερομηνία λήξης',
    start_date: 'Ημερομηνία έναρξης',
    recap: 'Περίληψη',
    start: 'Αρχή',
    starting_point: 'Αναχώρηση',
    end: 'τέλος',
    finish_point: 'φτάνω',
    duration: 'Διάρκεια',
    distance: 'Απόσταση',
    daily_distance: 'Τα χλμ ταξίδευαν καθημερινά',
    distance_total: 'Συνολική απόσταση',
    gps_distance: 'Απόσταση (GPS)',
    distance_gps: 'Km που ταξιδεύετε καθημερινά (GPS)',
    pause: 'Διακοπή',
    segment: 'Ασχολείται με',
    start_time: 'Ωρα έναρξης',
    end_time: 'Τέλος χρόνου',
    service_time: 'Χρόνος εξυπηρέτησης',
    waypoint_load: 'Φόρτωση επί του σκάφους',
    state: 'κατάσταση',
    violated_plafond: 'Παραβίαση οροφής',
    traffic_delay: 'Καθυστέρηση κυκλοφορίας',
    company: 'Πρακτορείο',
    address: 'Διεύθυνση',
    position: 'Θέση',
    route: 'Μονοπάτι',
    filter_date: 'Φιλτράρισμα κατά ημερομηνία',
    warning: 'Προσοχή',
    no_warning: 'Καμία προειδοποίηση',
    alerts: 'Ειδοποιήσεις',
    trucks_with_alerts: 'Οχήματα με προειδοποιήσεις',
    bluetooth_devices: 'Συσκευές Bluetooth',
    no_bluetooth_devices: 'Δεν βρέθηκαν συσκευές Bluetooth',
    connected: 'Συνδεδεμένος',
    disconnected: 'Ασύνδετος',
    bluetooth_types: {
        temperature_probe: 'Αισθητήρας θερμοκρασίας',
        door_probe: 'Πόρτα και αισθητήρας θερμοκρασίας'
    },
    back_to_home: 'Πίσω στο σπίτι',
    cerca: 'Κοντά',
    search_vehicle: 'Αναζήτηση οχήματος',
    filter: 'Φίλτρο',
    filter_label: 'Φίλτρο',
    filters_label: 'Φίλτρα',
    filter_by: 'Φιλτράρισμα κατά',
    operations: 'Λειτουργίες',
    filter_vehicles: 'Φιλτράρισμα οχημάτων',
    filter_applied: 'Το φίλτρο εφαρμόστηκε με επιτυχία',
    filter_canceled: 'Το φίλτρο διαγράφηκε με επιτυχία',
    order_by: 'Ταξινόμηση κατά',
    order: 'Σειρά',
    ascending: 'Μεγαλώνοντας',
    descending: 'Φθίνων',
    value: 'αξία',
    select_filter: 'Επιλέξτε φίλτρο',
    sort_vehicles: 'Παραγγελία οχημάτων',
    sort_for_alphanumeric: 'Αλφαβητικός',
    sort_for_lastpoint: 'Χρονολογικός',
    sort_for_company: 'Πρακτορείο',
    sort_for_activity: 'Δραστηριότητα',
    company_select: 'Επιλέξτε εταιρεία',
    no_companies_founded: 'Δεν βρέθηκαν εταιρείες',
    multicompany: 'Φιλτράρισμα ανά εταιρεία',
    no_vehicles_founded: 'Δεν βρέθηκαν οχήματα',
    print: 'Τύπος',
    export: 'Εξαγωγή',
    print_recap: 'Εκτύπωση περίληψης',
    select_date: 'Επιλέξτε μια ημερομηνία',
    add: 'Προσθήκη',
    add_new: 'Προσθεσε νεο',
    delete: 'Διαγράφω',
    edit: 'Επεξεργασία',
    options: 'Επιλογές',
    login: 'Σύνδεση',
    logout: 'Βγες έξω',
    logout_confirm: 'Είσαι σίγουρος ότι θέλεις να παραιτηθείς?',
    login_button: 'Σύνδεση',
    connect: 'Συνδέω-συωδεομαι',
    logged: 'Συνδεδεμένος',
    manage: 'Διαχειρίζονται',
    loading: 'Φόρτωση',
    loading_timeout: 'Σφάλμα δικτύου, φορτώστε ξανά τη σελίδα ή δοκιμάστε ξανά αργότερα',
    go_back: 'Ελα πισω',
    select_coordinates: 'Επιλέξτε συντεταγμένες',
    other: 'Αλλα',
    options_general: 'Γενικός',
    options_language: 'Γλώσσα',
    change_interface_language: 'Επιλέξτε γλώσσα διεπαφής',
    change_infringment_language: 'Επιλέξτε χώρα παράβασης',
    change_timezone: 'Επιλέξτε ζώνη ώρας',
    save_language_settings: 'Αποθήκευση ρυθμίσεων γλώσσας',
    vehicle_list_options: 'Λίστα οχημάτων',
    select_view: 'Επιλέξτε προβολή',
    select_fields_placeholder: 'Επιλέξτε τα πεδία για προβολή',
    selected_field: 'Επιλεγμένο πεδίο',
    selected_fields: 'Επιλεγμένα πεδία',
    list: 'Λίστα',
    table: 'Τραπέζι',
    view_options: 'Προβολή επιλογών',
    fluid_table: 'Δυναμικό πλάτος',
    fluid_table_descr: 'Κάντε το πλάτος του τραπεζιού ρευστό με βάση τα επιλεγμένα πεδία',
    vehicle_fields_list: 'Ποιες πληροφορίες θέλετε να εμφανίζονται για κάθε όχημα; (Μέγ. 3)',
    vehicle_fields_list_warning:
        'Προσοχή: το πεδίο δεν θα εμφανίζεται εάν δεν είναι διαθέσιμο για το όχημα',
    vehicle_identification: 'Πώς θέλετε να αναγνωρίζεται το όχημα στην κύρια λίστα;',
    apply_to_marker: 'Εφαρμόστε αυτή τη ρύθμιση και σε οχήματα στον χάρτη',
    element_icon_section: 'Εικονίδιο στοιχείου',
    element_title_section: 'Τίτλος αντικειμένου',
    element_fields_list_section: 'Λίστα πεδίων στοιχείων',
    element_icon_description: 'Επιλέξτε το εικονίδιο που θα εμφανιστεί για το αντικείμενο',
    element_icon: 'Εικόνισμα',
    element_icon_color: 'Χρώμα εικονιδίου',
    options_map: 'Επιλογές χάρτη',
    vehicles_clustering: 'Ομαδοποίηση οχημάτων',
    vehicles_clustering_warning: 'Η ενημέρωση αυτής της ρύθμισης απαιτεί επαναφόρτωση της σελίδας',
    options_marker: 'Επιλογές δείκτη',
    size: 'Διάσταση',
    font_size: 'Μέγεθος κειμένου',
    font_color: 'Χρώμα κειμένου',
    background_color: 'Χρώμα του φόντου',
    opacity: 'Διαφάνεια',
    color: 'Χρώμα',
    small: 'Μικρό',
    medium: 'Μεσαίο',
    large: 'Εξαιρετική',
    additional_info: 'Επιπλέον πληροφορίες',
    analysis_options: 'Ανάλυση διαδρομής',
    options_chart: 'Επιλογές γραφήματος',
    options_timeline: 'Επιλογές χρονοδιαγράμματος',
    hide_timeline: 'Απόκρυψη χρονολογίου',
    map: 'Χάρτης',
    silica: 'Πυρίτιο',
    google: 'Google',
    google_satellite: 'Google Satellite',
    sandbox: 'Sandboxes',
    silkysand: 'Silkysand',
    gravelpit: 'Χαλίκι',
    open_street_maps: 'Ανοίξτε τους Χάρτες οδών',
    arcgis_satellite: 'ArcGIS Satellite',
    traffic: 'ΚΙΝΗΣΗ στους ΔΡΟΜΟΥΣ',
    heavy_vehicles_ban: 'Απαγόρευση βαρέων οχημάτων',
    driver: 'Οδηγός',
    no_driver: 'Χωρίς οδηγό',
    drivers: 'Οδηγοί',
    driver_card: 'Κάρτα προγράμματος οδήγησης',
    name: 'Ονομα',
    surname: 'Επώνυμο',
    driver_activity: 'Δραστηριότητα οδηγού',
    driver_status: 'Κατάσταση οδηγού',
    activity_type: 'Είδος δραστηριότητας',
    activity: 'Δραστηριότητα',
    no_activity: 'Καμία δραστηριότητα',
    driver_at_rest: 'Οδηγός σε ηρεμία',
    driver_available: 'Διαθέσιμο πρόγραμμα οδήγησης',
    driver_at_work: 'Οδηγός στη δουλειά',
    driver_driving: 'Οδήγηση οδηγού',
    inactive_vehicle: 'Όχημα ανενεργό',
    last_driver_detected: 'Εντοπίστηκε τελευταίος οδηγός',
    rest: 'Υπόλοιπο',
    work: 'Δουλειά',
    driving: 'Οδηγός',
    availability: 'Διαθεσιμότητα',
    codriving: 'Οδηγήστε με πλήρωμα',
    codriver: 'Πλήρωμα',
    break: 'Παύση συνεδρίας',
    daily_rest: 'Καθημερινή ανάπαυση',
    violation: 'Παράβαση',
    to_begin_within: 'Να ξεκινήσει από',
    to_end_within: 'Να τελειώσει από',
    after_total_time_travel: 'Μετά από ένα ταξίδι συνολικά',
    after_total_distance_travel: 'Μετά από μια συνολική απόσταση που διανύθηκε',
    residual_commitment_type: 'Είδος υπολειπόμενης δέσμευσης',
    residual_commitment: 'Υπολειμματική δέσμευση',
    commitment_hours: 'Ώρες δέσμευσης',
    commitment_hours_description:
        'Βρείτε όλα τα οχήματα των οποίων οι οδηγοί έχουν υπολειπόμενη δέσμευση μεγαλύτερη από αυτή που υποδεικνύεται',
    driver_plafond: 'Οροφή οδηγού',
    residual_driving: 'Υπολειμματική καθοδήγηση',
    residual_bonuses: 'Υπολειμματικά μπόνους',
    consider_bonuses: 'Ο χρόνος υπολογίζεται λαμβάνοντας υπόψη τα υπολειπόμενα μπόνους',
    current_activity: 'Τρεχων ΔΡΑΣΤΗΡΙΟΤΗΤΑ',
    next_activity: 'Επόμενη δραστηριότητα',
    view_plafond_detail: 'Δείτε λεπτομέρειες οροφής',
    card: 'Χαρτί',
    driver_change: 'Αλλαγή οδηγού',
    shared_links: 'Κοινόχρηστοι σύνδεσμοι',
    gf: 'ΜΕΤΑ Εντοπίστηκε',
    analyze: 'Αναλύει',
    plan: 'Σχέδιο',
    vehicle: 'Οχημα',
    vehicles: 'Οχήματα',
    vehicle_info: 'Πληροφορίες οχήματος',
    vehicle_type: 'Τύπος οχήματος',
    vehicle_body: 'Αμάξωμα οχήματος',
    truck_status: 'Κατάσταση οχήματος',
    brand: 'Μάρκα',
    model: 'Μοντέλο',
    no_card: 'Δεν έχει τοποθετηθεί χαρτί',
    no_card_driving: 'Οδήγηση χωρίς χαρτί',
    speed: 'Ταχύτητα',
    tacho_speed: 'Ταχύτητα (ταχογράφος)',
    gps_speed: 'Ταχύτητα (GPS)',
    level: 'Επίπεδο',
    battery: 'Τύμπανα',
    low_battery_level: 'Χαμηλό επίπεδο μπαταρίας',
    fuel: 'Καύσιμα',
    fuel_level: 'Επίπεδο καυσίμου',
    low_fuel_level: 'Χαμηλό καύσιμο',
    consumption: 'Κατανάλωση',
    consumptions: 'Κατανάλωση',
    fuel_consumption: 'Κατανάλωση καυσίμου',
    fuel_avg_consumption: 'Μέση ημερήσια κατανάλωση καυσίμου',
    altitude: 'Υψόμετρο',
    weight: 'Βάρος',
    lastpoint_weight: 'Βάρος (τελευταίο σημείο)',
    vehicle_weight: 'Βάρος (t)',
    vehicle_length: 'Μήκος (m)',
    vehicle_width: 'Πλάτος (m)',
    vehicle_height: 'Ύψος (m)',
    odometer: 'Οδόμετρο',
    odometer_tacho: 'Χιλιομετρητής (ταχογράφος)',
    odometer_gps: 'Χιλιομετρητής (GPS)',
    gps_odometer: 'Χιλιομετρητής (GPS)',
    last_point: 'Τελευταίο σημείο',
    moving: 'Σε κίνηση',
    stopped: 'Σταμάτησε',
    engine_on: 'Ο κινητήρας λειτουργεί',
    ignition: 'Ανάφλεξη αναμμένη',
    engine_off: 'Σβηστός κινητήρας',
    engine_on_off: 'Εκκίνηση του κινητήρα',
    ignition_on: 'Ανάφλεξη αναμμένη',
    ignition_off: 'Σβηστή ανάφλεξη',
    device: 'Συσκευή',
    plate: 'Πλάκα',
    chassis: 'Σασί',
    status: 'Κατάσταση',
    custom_id: 'Προσαρμοσμένο όνομα',
    heading: 'Κατεύθυνση',
    rpm_engine: 'στροφές κινητήρα',
    pto_active: 'PTO ενεργός',
    pto_state: 'Κατάσταση PTO',
    device_timestamp: 'Χρονική σήμανση',
    info: 'Πληροφορίες',
    solar_day_report: 'Αναφορά ημερολογιακής ημέρας',
    telemetry: 'Τηλεμετρία',
    parameters: 'Παράμετροι',
    engine_coolant_temp: 'Θερμοκρασία κινητήρα',
    engine_rpm: 'στροφές κινητήρα',
    dallas_temp_1: 'Θερμοκρασία 1',
    dallas_temp_2: 'Θερμοκρασία 2',
    dallas_temp_id_1: 'Αναγνωριστικό θερμοκρασίας 1',
    dallas_temp_id_2: 'Αναγνωριστικό θερμοκρασίας 2',
    digital_input_1: 'Ψηφιακή είσοδος 1',
    digital_input_2: 'Ψηφιακή είσοδος 2',
    digital_input_3: 'Ψηφιακή είσοδος 3',
    digital_input_4: 'Ψηφιακή είσοδος 4',
    digital_input_5: 'Ψηφιακή είσοδος 5',
    battery_level: 'Επίπεδο μπαταρίας',
    adblue_liters: 'Λίτρα Adblue',
    adblue_level: 'Επίπεδο Adblue',
    gnss_status: 'Κατάσταση GNSS',
    gnss_status_values: [
        'Φθαρμένο',
        'Ενεργό, χωρίς GPS',
        'Ενεργό, χωρίς δορυφόρους',
        'Ενεργοποιημένο, με δορυφόρους',
        'Ανοιχτό, στον ύπνο',
        'Προστατευμένη κατάσταση'
    ],
    gnss_off: 'GNSS απενεργοποιημένο',
    gnss_on_fix: 'GNSS ενεργοποιημένο, με δορυφόρους',
    gnss_on_without_fix: 'GNSS ενεργοποιημένο, χωρίς δορυφόρους',
    gnss_on_sleep: 'GNSS ενεργοποιημένο, στον ύπνο',
    gnss_on_protection: 'GNSS ενεργοποιημένο, κατάσταση προστασίας',
    gsm_signal: 'Σήμα GSM',
    tacho_source: 'Πηγή ταχογράφου',
    external_voltage: 'Διατροφή',
    tacho_overspeed: 'Ταχογράφος υπερβολικής ταχύτητας',
    no_points_title: 'Τελευταία δραστηριότητα πριν από περισσότερες από 24 ώρες',
    no_points: 'Αυτό το όχημα δεν έχει στείλει πόντους για περισσότερες από 24 ώρες',
    clutch: 'Συμπλέκτης',
    clutch_switch: 'Διακόπτης συμπλέκτη',
    brake_pedal: 'Φρένο',
    accelerator_pedal: 'Γκάζι',
    pedal_released: 'Το πεντάλ απελευθερώθηκε',
    pedal_pressed: 'Το πεντάλ πατήθηκε',
    data_mode: 'Λειτουργία δεδομένων',
    home_stop: 'Κλειδωμένο στη θέση του',
    home_moving: 'Εν κινήσει επί τόπου',
    roaming_stop: 'Κολλημένος στην περιαγωγή',
    roaming_moving: 'Εν κινήσει περιαγωγή',
    unknown_stop: 'Άγνωστη σύλληψη',
    unknown_moving: 'Μετακίνηση άγνωστη',
    engine_coolant_temperature: 'Θερμοκρασία ψυκτικού',
    engine_temperature: 'Θερμοκρασία κινητήρα',
    cruise_control_active: 'Το Cruise Control είναι ενεργό',
    adblue_off: 'Απενεργοποίηση του Adblue',
    adblue_red: 'Adblue red',
    adblue_yellow: 'Adblue yellow',
    adblue_info: 'Πληροφορίες Adblue',
    charger_notconnected: 'Ο φορτιστής δεν είναι συνδεδεμένος',
    charger_connected: 'Συνδεδεμένος φορτιστής',
    tacho_unknown: 'Αγνωστος',
    tacho_kline: 'K-Line',
    tacho_allcan: 'ΛΕΩΦΟΡΕΙΟ-CAN',
    tacho_can: 'ΛΕΩΦΟΡΕΙΟ-CAN',
    tacho_fms: 'FMS',
    vehicle_not_found: 'Το όχημα στο οποίο προσπαθείτε να αποκτήσετε πρόσβαση δεν είναι διαθέσιμο',
    vehicle_with_lock_commands: 'Όχημα με χειριστήρια κλειδώματος',
    lock: 'ΟΙΚΟΔΟΜΙΚΟ ΤΕΤΡΑΓΩΝΟ',
    unlock: 'Ξεκλείδωμα',
    send: 'Στείλετε',
    command_sent: 'Η εντολή στάλθηκε με επιτυχία',
    turn_on: 'φως αναμμένο',
    turned_on: 'Αναμμένο',
    turn_off: 'Σβήνω',
    turned_off: 'Φθαρμένο',
    confirm_send_command: 'Είστε βέβαιοι ότι θέλετε να στείλετε την εντολή;',
    temperature: {
        title: 'Ανιχνευτές θερμοκρασίας',
        probe: [
            'Ανιχνευτής 1',
            'Ανιχνευτής 2',
            'Ανιχνευτής 3',
            'Ανιχνευτής 4',
            'Ανιχνευτής 5',
            'Ανιχνευτής 6'
        ]
    },
    no_power_supply: 'Καμία δικαιοδοσία',
    no_gps: 'Χωρίς GPS',
    tacho_clock_from_updated_error: 'Σφάλμα συγχρονισμού ρολογιού ταχογράφου',
    tacho_clock_from_device_error: 'Σφάλμα συγχρονισμού μεταξύ ταχογράφου και μονάδας ελέγχου',
    device_timestamp_error: 'Σφάλμα ημερομηνίας ECU',
    select_color_mode: 'Επιλέξτε λειτουργία χρώματος',
    light_mode: 'Λειτουργία φωτός',
    dark_mode: 'Σκοτεινή λειτουργία',
    color_blind_mode: 'Λειτουργία αχρωματοψίας',
    color_blind_dark_mode: 'Αχρωματοψία σκοτεινή λειτουργία',
    geofleet: {
        mainpanel: {
            pinned_vehicles: 'Επιλεγμένα οχήματα',
            search_trucks_title: 'Αναζήτηση οχημάτων',
            search_trucks_tags_title: 'Συσχετισμένες ετικέτες',
            search_trucks_status_title: 'Δραστηριότητα οχημάτων',
            search_trucks_driver_status_title: 'Δραστηριότητα οδηγού',
            search_near_trucks_title: 'Βρείτε κοντινά οχήματα'
        },
        detailspanel: {
            bind_vehicle: 'Ζεύγος οχήματος',
            add_new_tag: 'Προσθήκη ως νέα ετικέτα',
            select_new_tag: 'Επιλέξτε ή αναζητήστε μια ετικέτα',
            binded_tags: 'Συσχετισμένες ετικέτες',
            binded_tag: 'Συσχετισμένη ετικέτα'
        },
        analyzer: {
            vehicle_activity_analisys: 'Ανάλυση δραστηριότητας οχήματος',
            timeline_separator: 'Ομάδα κατά',
            loading_error: 'Παρουσιάστηκε σφάλμα κατά τη φόρτωση δεδομένων'
        },
        search_resources: {
            title: 'Αναζήτηση πόρων',
            description:
                'Βρείτε τα πρατήρια καυσίμων, τους χώρους στάθμευσης και τις προσβάσιμες περιοχές που βρίσκονται πιο κοντά στο όχημά σας.'
        }
    },
    analisys: {
        title: 'Ανάλυση διαδρομής',
        select_telemetries: 'Επιλέξτε τις τηλεμετρίες που θα εμφανίζονται στην ανάλυση διαδρομής',
        select_telemetries_description: 'Μπορείτε να επιλέξετε έως και 4 τηλεμετρίες για εμφάνιση',
        chart_groups: {
            movement_data: 'Δεδομένα κίνησης',
            engine_data: 'Στοιχεία κινητήρα',
            vehicle_data: 'Δεδομένα οχήματος',
            sensors_data: 'Δεδομένα αισθητήρα',
            driver_data: 'Δεδομένα προγράμματος οδήγησης',
            fuel_data: 'Δεδομένα καυσίμου'
        }
    },
    planner: {
        user_config: 'Διαμορφώσεις χρήστη',
        no_user_config: 'Δεν υπάρχουν διαμορφώσεις χρήστη για προβολή',
        freight_access: 'Πρόσβαση σε σακούλες φορτίου',
        vehicle_actual_position: 'Τρέχουσα θέση οχήματος',
        plan_route: 'Σχεδιάστε τη διαδρομή',
        select_on_map: 'Επιλέξτε ένα σημείο στο χάρτη',
        select_on_map_option: 'Επιλέξτε στον χάρτη',
        fill_route_info: 'Συμπληρώστε τις πληροφορίες διαδρομής',
        fill_resources: 'Επιλέξτε το σημείο που θέλετε να προσθέσετε ως σημείο',
        fill_waypoints: 'Επιλέξτε τα στάδια',
        load_breaks: 'Ανάπαυση φορτίου',
        load_alternative_routes: 'Φορτώστε εναλλακτικές διαδρομές',
        savedroutes: {
            my_routes: 'Οι διαδρομές μου',
            saved_routes: 'Αποθηκευμένες διαδρομές',
            no_saved_routes: 'Δεν έχετε αποθηκεύσει ακόμη διαδρομές',
            success_saved_route: 'Η διαδρομή σας αποθηκεύτηκε με επιτυχία',
            error_saved_route: 'Παρουσιάστηκε σφάλμα κατά την αποθήκευση της διαδρομής',
            success_deleted_route: 'Η διαδρομή σας διαγράφηκε με επιτυχία',
            error_deleted_route: 'Παρουσιάστηκε σφάλμα κατά τη διαγραφή της διαδρομής'
        },
        resources: {
            area_search: 'Αναζήτηση εντός ακτίνας',
            station_services: 'Συμπεριλαμβανομένων αυτών των υπηρεσιών',
            parking_services: 'Υπηρεσίες στάθμευσης',
            select_rating: 'Επιλέξτε τη βαθμολογία',
            select_security: 'Επιλέξτε ασφάλεια',
            empty_search: 'Η αναζήτηση δεν βρήκε στοιχεία για εμφάνιση',
            delivery_nation: 'Χώρα παράδοσης',
            fai_featured: 'συνδεδεμένη με τη Lumesia',
            self_service: 'Αυτοεξυπηρέτηση',
            served: 'Σερβίρεται',
            total_parking_spots: 'Σύνολο θέσεων',
            rating: 'Αύξηση',
            reviews: 'Κριτικές',
            security: 'Ασφάλεια',
            price_per_night: 'Τιμή ανά διανυκτέρευση'
        },
        emissions_labels: 'Εκπομπές',
        emissions: {
            fuelconsumption: 'Κατανάλωση καυσίμου',
            hydrocarbons: 'Υδρογονάνθρακες',
            methane: 'Μεθάνιο',
            hydrocarbonsexceptmethane: 'Υδρογονάνθρακες εκτός από το μεθάνιο',
            carbonmonoxide: 'Μονοξείδιο του άνθρακα',
            carbondioxide: 'Διοξείδιο του άνθρακα',
            fossilcarbondioxide: 'Απολιθωμένο διοξείδιο του άνθρακα',
            sulphurdioxide: 'Διοξείδιο του θείου',
            nitrogenoxides: 'Οξείδια του αζώτου',
            nitrogendioxide: 'Διοξείδιο του αζώτου',
            nitrousoxide: 'Οξείδιο του αζώτου',
            ammonia: 'Αμμωνία',
            benzene: 'Βενζόλιο',
            particles: 'Σωματίδια',
            numberofparticles: 'Αριθμός σωματιδίων'
        },
        plafond_configuration: {
            title: 'Διαμόρφωση οροφής',
            driving_time_regulation: 'Χρόνος οδήγησης',
            last_time_the_driver_worked: 'Τελευταία δραστηριότητα οδηγού',
            eu_driving_time_regulation_for_single_day: 'Μοναδική ημέρα',
            eu_driving_time_regulation_for_single_long_day: 'Μονοήμερο (με μπόνους)',
            eu_driving_time_regulation_for_multiple_day: 'Περισσότερες μέρες',
            eu_driving_time_regulation_for_team_and_multiple_day: 'Πολλαπλές ημέρες (με πλήρωμα)',
            accumulated_driving_time_since_lastbreak: 'Ώρες οδήγησης από το τελευταίο διάλειμμα',
            accumulated_driving_time_since_lastdailyrest:
                'Ώρες οδήγησης από την τελευταία ημερήσια ανάπαυση',
            accumulated_travel_time_since_lastdailyrest:
                'Ώρες ταξιδιού από την τελευταία ημερήσια ανάπαυση'
        },
        set_route: 'Ορισμός διαδρομής',
        select_profile: 'Επιλέξτε προφίλ',
        select_co2_class: 'Επιλέξτε κατηγορία εκπομπών',
        class: 'Τάξη',
        billed_roads: 'Δρόμοι με διόδια',
        waypointevent: 'Να σταματήσει',
        waypoints_label: 'Στάδια',
        add_waypoint: 'Προσθήκη σταδίου',
        delete_waypoint: 'Διαγραφή σταδίου',
        select_waypoint: 'Επιλέξτε στάδιο',
        select_resource: 'Επιλέξτε το σημείο που θέλετε να προσθέσετε ως σημείο',
        last_waypoint: 'Τελευταία στάση',
        waypoints_check: 'Υπάρχουν στάδια που πρέπει να ολοκληρωθούν',
        waypoints_required: 'Απαιτούνται τουλάχιστον 2 βήματα',
        countryevent: 'κατάσταση',
        tollevent: 'Διόδια',
        event: 'Εκδήλωση',
        alpega: 'Alpega',
        alpega_login: 'Είσοδος Alpega',
        alpega_searches: 'Η έρευνά σας',
        timocom: 'Timocom',
        pickup: 'Αποσύρω',
        delivery: 'Διανομή',
        extimated_cost: 'Εκτιμώμενο κόστος',
        calculate_route: 'Υπολογισμός διαδρομής',
        calculate_isocrona: 'Υπολογίστε το ισόχρονο',
        complete_step_2: 'Ολοκληρώστε το βήμα 2',
        reset_route: 'Νέα διαδρομή',
        route_error: 'Παρουσιάστηκε σφάλμα κατά τον υπολογισμό της διαδρομής',
        edit_route: 'Επεξεργασία διαδρομής',
        save_route: 'Αποθήκευση διαδρομής',
        delete_route: 'Διαγραφή διαδρομής',
        route_name: 'Όνομα διαδρομής',
        override_route: 'Αντικατάσταση διαδρομής',
        save_as_new_route: 'Αποθήκευση νέας διαδρομής',
        calculate_route_for: 'Υπολογισμός διαδρομής για',
        ptvexport: 'Εξαγωγή σε PTV Navigator',
        isocrona: 'Προσβάσιμες περιοχές',
        reset_isocrona: 'Επαναφέρετε τις προσβάσιμες περιοχές',
        search_location: 'Αναζήτηση τοποθεσιών',
        search_station: 'Αναζήτηση διανομέα',
        search_parking: 'Αναζήτηση για πάρκινγκ',
        search_poi: 'Αναζήτηση POI',
        include_plafond: 'Συμπεριλάβετε το όριο οδηγού στον υπολογισμό της διαδρομής',
        include_plafond_near_vehicles:
            'Βρείτε οχήματα με οδηγούς με επαρκή υπολειπόμενη δέσμευση να φτάσουν στην τοποθεσία που αναζητήθηκε',
        violated_plafond: 'Η επιλεγμένη διαδρομή παραβιάζει το όριο του οδηγού'
    },
    sharedlinks: {
        back_to_links: 'Επιστρέψτε στους συνδέσμους',
        create_link: 'Δημιουργήστε συνδέσμους',
        update_link: 'Ενημέρωση συνδέσμου',
        edit_link: 'Επεξεργασία συνδέσμου',
        update_link_expire_date: 'Ενημέρωση ημερομηνίας λήξης',
        delete_link: 'Διαγραφή συνδέσμου',
        delete_link_confirm: 'Διαγραφή του επιλεγμένου συνδέσμου;',
        all_your_shared_links: 'Όλοι οι κοινόχρηστοι σύνδεσμοί σας',
        no_shared_links: 'Δεν έχετε μοιραστεί ακόμη κανένα όχημα',
        vehicles_link: 'Σύνδεσμοι για οχήματα',
        select_share_vehicles: 'Επιλέξτε οχήματα για κοινή χρήση',
        select_start_expire_date: 'Επιλέξτε ημερομηνία έναρξης και λήξης',
        created_link_error: 'Σφάλμα δημιουργίας συνδέσμου',
        created_link_success: 'Ο σύνδεσμος δημιουργήθηκε με επιτυχία',
        updated_link_error: 'Σφάλμα ενημέρωσης συνδέσμου',
        updated_link_success: 'Ο σύνδεσμος ενημερώθηκε με επιτυχία',
        service_description:
            'Μοιραστείτε αυτόν τον σύνδεσμο για να εμφανίσετε την παρακολούθηση επιλεγμένων οχημάτων',
        link_preview: 'Προεπισκόπηση συνδέσμου',
        share_single_vehicle: 'Μοιραστείτε την τοποθεσία αυτού του οχήματος',
        track_vehicle_position: 'Παρακολουθήστε τη θέση των επιλεγμένων οχημάτων',
        start_date_warning: 'Δεν είναι δυνατή η αλλαγή της ημερομηνίας έναρξης',
        start_date_condition:
            'Εάν θέλετε να αλλάξετε την ημερομηνία έναρξης, πρέπει να δημιουργήσετε έναν νέο σύνδεσμο',
        expire_date_description:
            'Υποδεικνύει μια ημερομηνία λήξης, μετά την οποία αυτός ο σύνδεσμος δεν θα είναι πλέον διαθέσιμος',
        start_expire_date_description:
            'Υποδείξτε μια αρχική ημερομηνία, από την οποία θα είναι διαθέσιμος αυτός ο σύνδεσμος, και μια ημερομηνία λήξης, μετά την οποία αυτός ο σύνδεσμος δεν θα είναι πλέον διαθέσιμος:'
    },
    viewsharedlink: {
        invalid_date_range:
            'Το εύρος ημερομηνιών που επιλέξατε δεν είναι έγκυρο για αυτόν τον σύνδεσμο',
        too_early_link:
            'Ο σύνδεσμος στον οποίο προσπαθείτε να αποκτήσετε πρόσβαση δεν είναι ακόμη διαθέσιμος',
        too_late_link:
            'Ο σύνδεσμος στον οποίο προσπαθείτε να αποκτήσετε πρόσβαση δεν υπάρχει πλέον',
        show_route: 'Εμφάνιση διαδρομής'
    },
    tags: {
        manage_tags: 'Διαχείριση ετικετών',
        add_tag: 'Προσθέστε ετικέτες',
        add_tag_placeholder: 'Εισαγάγετε το όνομα της ετικέτας',
        add_tag_error: 'Σφάλμα κατά την προσθήκη ετικέτας',
        add_tag_success: 'Η ετικέτα προστέθηκε με επιτυχία',
        delete_tag: 'Διαγραφή ετικετών',
        delete_tag_confirm: 'Διαγραφή της επιλεγμένης ετικέτας;',
        delete_tag_error: 'Σφάλμα κατά τη διαγραφή της ετικέτας',
        delete_tag_success: 'Η ετικέτα διαγράφηκε επιτυχώς',
        update_tag: 'Ενημέρωση ετικετών',
        update_tag_error: 'Σφάλμα ενημέρωσης ετικέτας',
        update_tag_success: 'Η ετικέτα ενημερώθηκε με επιτυχία',
        all_your_tags: 'Όλες οι ετικέτες σας',
        no_tags: 'Δεν έχετε δημιουργήσει ακόμη ετικέτες',
        tag_name: 'Όνομα ετικέτας'
    },
    manage_poi: {
        title: 'Διαχείριση POI',
        your_pois: 'Τα POI σας',
        add_poi: 'Προσθήκη POI',
        no_pois: 'Δεν έχετε δημιουργήσει ακόμα κανένα POI',
        fields: {
            name: 'Ονομα',
            type: 'Ο τύπος',
            color: 'Χρώμα',
            note: 'Σημείωση',
            address: 'Αναζήτηση διεύθυνσης',
            latitude: 'Γεωγραφικό πλάτος',
            longitude: 'Γεωγραφικό μήκος',
            radius: 'Ακτίνα αναζήτησης',
            radius_description:
                'Για να ενεργοποιήσετε το geofencing, ορίστε μια τιμή μεγαλύτερη από το μηδέν'
        },
        types_poi: {
            all: 'Ολοι',
            headquarter: 'Ιστοσελίδα',
            truck_washes: 'Πλυντήρια αυτοκινήτων φορτηγών',
            blacklist: 'Προγράφω',
            end_of_the_line: 'Τέρμα',
            customers: 'Πελάτες',
            stops: 'Να σταματήσει',
            suppliers: 'Παρόχους',
            interports: 'Interports',
            markets: 'Αγορές',
            large_square: 'Τετράγωνα',
            poi_distributors: 'ΤΟΤΕ Διανομείς',
            poi_workshops: 'ΜΕΤΑ Εργαστήρια',
            harbors: 'λιμάνια',
            restaurants: 'Εστιατόρια',
            stations: 'Σταθμοί'
        },
        messages: {
            add_poi_error: 'Σφάλμα κατά την προσθήκη POI',
            add_poi_success: 'ΜΕΤΑ προστέθηκε με επιτυχία',
            update_poi_error: 'Σφάλμα ενημέρωσης POI',
            update_poi_success: 'ΜΕΤΑ ενημερώθηκε με επιτυχία',
            delete_poi_error: 'Σφάλμα κατά τη διαγραφή POI',
            delete_poi_success: 'ΜΕΤΑ διαγράφηκε επιτυχώς'
        }
    },
    vehicles_cross: {
        title: 'Διέλευση οχήματος',
        fields: {
            address: 'Διεύθυνση',
            radius: 'ακτίνα κύκλου',
            time_interval: 'Χρονική πάροδος',
            time_interval_description: 'Επιλέξτε ένα μέγιστο χρονικό εύρος 24 ωρών'
        },
        messages: {
            max_time_interval: 'Το μέγιστο χρονικό εύρος είναι 24 ώρες',
            error_searching: 'Παρουσιάστηκε σφάλμα κατά την αναζήτηση οχημάτων',
            searching: 'Έρευνα σε εξέλιξη...',
            long_loading: 'Η αναζήτηση μπορεί να διαρκέσει λίγα λεπτά, περιμένετε...',
            no_vehicles_cross: 'Δεν βρέθηκαν οχήματα'
        }
    },
    plafonds: {
        session: 'Διάλειμμα συνεδρίας',
        break: 'Διάλειμμα',
        drive_occurring: 'Είναι σε εξέλιξη μια περίοδος οδήγησης:',
        drive_max_label: 'Μέγιστος χρόνος οδήγησης',
        drive_max_value: '4h 30m',
        drive_done: 'Οδήγηση που πραγματοποιήθηκε',
        session_title: 'Πληροφορίες για τη Διακοπτόμενη Παύση',
        session_tooltip: 'Είναι δυνατόν να γίνει διακοπτόμενη παύση',
        session_done: 'Έχει καταγραφεί μια παύση τουλάχιστον 15 λεπτών.',
        session_info: 'Έχει ήδη γίνει το πρώτο μέρος μιας Διακοπτόμενης Παύσης',
        session_descr:
            "Υπενθυμίζουμε ότι σύμφωνα με την ισχύουσα νομοθεσία είναι δυνατή μια παύση 15'+30' ή 15'+15'+15' (ανάλογα με τον τύπο της μεταφοράς).",
        session_choice: 'Είναι δυνατόν να κάνετε τουλάχιστον <b>30m</b> εντός των επόμενων ',
        brake_start: 'Έναρξη παύσης',
        brake_done: 'Πραγματοποιήθηκε παύση',
        daily: 'Ημερήσια',
        weekly: 'Εβδομαδιαία',
        driver_in_drive: 'Οδηγείς',
        driver_in_rest: 'Υπολειμματική ανάπαυση',
        driver_in_work: 'Δουλειά',
        driver_in_available: 'Διαθεσιμότητα',
        driver_in_availability: 'Διαθεσιμότητα',
        current_activity: 'Τρεχων ΔΡΑΣΤΗΡΙΟΤΗΤΑ',
        mini_plafond: 'Μίνι οροφής',
        last_update: 'Τελευταία ενημέρωση',
        panel_title: 'Πάνελ οροφής',
        residual_commitment: 'Υπολειμματική δέσμευση',
        daily_driving: 'Καθημερινός οδηγός',
        weekly_guide: 'Εβδομαδιαίος οδηγός',
        biweekly_guide: 'Οδηγός δύο φορές την εβδομάδα',
        last_rest: 'Λήφθηκε η τελευταία εβδομαδιαία ανάπαυση',
        minimal_duration_next: 'Ελάχιστη διάρκεια στη συνέχεια',
        top_start_until: 'Να ξεκινήσει από',
        reduced_weekly_rest: 'Μειώθηκαν οι περίοδοι εβδομαδιαίας ανάπαυσης για αντιστάθμιση',
        time_to_compensate: 'Ώρα για αποζημίωση',
        time_to_compensate_until: 'Να αποζημιωθεί από',
        footer_message: '* ο χρόνος υπολογίζεται λαμβάνοντας υπόψη τα υπολειπόμενα μπόνους',
        driver_in_block: 'Το όριο του προγράμματος οδήγησης έχει εξαντληθεί',
        résidu_commitment: 'Υπολειμματική δέσμευση',
        week_guide: 'Οδηγός Hebdomadaire',
        réduit_weekly_rest: 'Repos hebdomadaires réduits pour compenser'
    },
    error_pages: {
        error_403: {
            title: 'Σφάλμα 403',
            description:
                'Αγαπητέ πελάτη, σας ενημερώνουμε ότι ο λογαριασμός σας δεν έχει διαμορφωθεί αυτήν τη στιγμή για πρόσβαση σε αυτήν την υπηρεσία. Για να λαμβάνετε πληροφορίες για την ίδια την υπηρεσία',
            retry_login: 'Δοκιμάστε να συνδεθείτε ξανά',
            contact_us: 'Επικοινωνήστε μαζί μας'
        }
    },
    ptv: {
        maxpermittedweight: 'βαρέων βαρών',
        minpermittedweight: 'Ελάχιστο βάρος',
        zonename: 'Περιοχή',
        unauthorizedtype: 'Μη εξουσιοδοτημένος κωδικός',
        maxemissionstandardeu: 'Κατηγορία εκπομπών',
        fueltype: 'Τύπος καυσίμου',
        loadtype: 'Είδος μεταφοράς',
        opening: 'Βόλτα',
        maxheight: 'μέγιστο ύψος',
        maxweight: 'βαρέων βαρών',
        maxwidth: 'Μέγιστο πλάτος',
        maxlength: 'Μέγιστο μήκος',
        absolutespeed: 'Ταχύτητα',
        hazardoustowaters: 'Απαγόρευση οχημάτων που μεταφέρουν επικίνδυνα για το νερό εμπορεύματα',
        hazardousgoods: 'Απαγόρευση οχημάτων με επικίνδυνα εμπορεύματα',
        combustibles: 'Απαγόρευση οχημάτων που μεταφέρουν καύσιμα',
        maxaxleload: 'Μέγιστο βάρος στους άξονες',
        totalpermittedweight: 'βαρέων βαρών',
        tunnelrestriction: 'Κωδικός περιορισμού σήραγγας',
        kingpintorearaxle: 'Απόσταση από βασιλιά έως άξονα',
        numberofaxles: 'Αριθμός αξόνων',
        length: 'Ουρά',
        delay: 'Καθυστέρηση',
        message: {
            text: 'Μήνυμα'
        }
    },
    options_interface: 'Διασύνδεση επιλογών'
}

<template>
    <div class="geofleet-map">
        <div class="h-100" :class="activeMap">
            <img :src="attributionLogo" class="attribution-logo" v-show="attributionLogo" />

            <l-map
                ref="map"
                :zoom="zoom"
                :center="center"
                :options="{
                    zoomControl: false,
                    gestureHandling: gestureHandling
                }"
                @ready="emitReadyMap"
                @fullscreenchange="fullScreen = !fullScreen"
            >
                <!-- MAP LAYERS -->
                <l-tile-layer
                    v-for="layer of filteredMapLayers"
                    :key="layer.name"
                    :name="layer.label"
                    :visible="activeMap == layer.name"
                    :url="layer.url"
                    :options="
                        Object.assign(layer.options, { lang: loggedUserParams?.Lingua || 'it-IT' })
                    "
                    :attribution="layer.attribution"
                    :class="layer.name"
                ></l-tile-layer>

                <!-- OPT LAYERS -->
                <l-tile-layer
                    v-for="layer of infoLayers"
                    :key="layer.name"
                    :name="layer.label"
                    :visible="layer.visible"
                    :url="layer.url"
                    :options="
                        Object.assign(layer.options, { lang: loggedUserParams?.Lingua || 'it-IT' })
                    "
                    :attribution="layer.attribution"
                    :tileLayerClass="tileLayerFunction(layer)"
                ></l-tile-layer>

                <!-- trucks -->
                <l-marker-cluster :options="clusterOpt" v-if="cluster === true">
                    <l-truck-marker
                        v-for="truck in localTrucks"
                        :key="truck.id"
                        :visible="truck.visible"
                        :truck="truck"
                        @click="handleFocusTruck(truck)"
                        @moving="centerMap"
                    />
                </l-marker-cluster>

                <template v-if="!cluster">
                    <l-truck-marker
                        v-for="truck in localTrucks"
                        :key="truck.id"
                        :visible="truck.visible"
                        :truck="truck"
                        @click="handleFocusTruck(truck)"
                        @moving="centerMap"
                    />
                </template>

                <!-- POI -->
                <poi-marker v-for="poi in pois" :key="poi.id" :poi="poi" />

                <!-- INFOBLU -->
                <l-geo-json :geojson="infobluEvents" :options="infoblu.options.events" />
                <l-geo-json
                    v-if="infobluAlerts"
                    :geojson="infoblu_alerts"
                    :options="infoblu.options.alerts"
                />

                <slot></slot>

                <l-control-fullscreen
                    class="custom-control"
                    position="topleft"
                    v-if="!noControls && (($mq != 'xs' && $mq != 'sm') || minimal)"
                />

                <l-control
                    class="custom-control search-control bg-main-bg-color"
                    position="topleft"
                    v-if="menu && !noControls && !minimal"
                >
                    <div id="search-bar" class="d-flex">
                        <div
                            class="search-layers-menu"
                            @click="searchBarTrigger = !searchBarTrigger"
                        >
                            <i
                                :class="
                                    searchBarTrigger
                                        ? 'fa-light fa-lg fa-magnifying-glass-minus'
                                        : 'fa-light fa-lg fa-magnifying-glass'
                                "
                            ></i>
                        </div>
                        <div v-if="searchBarTrigger" class="search-bar-input">
                            <golia-waypoint-search
                                id="address"
                                v-model="searchBar"
                                style="width: 300px"
                            />
                        </div>
                    </div>
                </l-control>

                <l-control class="custom-control" v-if="menu" position="topleft">
                    <div class="map-layers-button">
                        <div class="map-layers-menu"></div>
                        <form id="layers_form">
                            <b-form-group :label="__('map')">
                                <select v-model="activeMap">
                                    <option
                                        v-for="layer of filteredMapLayers"
                                        :key="layer.name"
                                        :value="layer.name"
                                    >
                                        {{ layer.label }}
                                    </option>
                                </select>
                            </b-form-group>
                            <span v-if="poisForm.length">
                                <hr />
                                <b-form-group :label="__('POI')">
                                    <b-form-checkbox
                                        v-for="poi of poisForm"
                                        :key="poi.id"
                                        :value="true"
                                        v-model="poi.selected"
                                    >
                                        <span>&nbsp;{{ poi.label }}</span>
                                    </b-form-checkbox>
                                </b-form-group>
                            </span>
                            <span v-if="$can('use', 'ptv_info_layers')">
                                <hr />
                                <!--
                                <b-form-group
                                    :label="__('INFOBLU')"
                                >
                                    <b-form-checkbox
                                        v-for="category of infoblu.fields"
                                        :key="category.name"
                                        :value="true"

                                        v-model="category.selected"
                                    >
                                        <span><i :class="infobluIcon(category.name)" class="ms-1"></i>&nbsp;{{ category.label }}</span>
                                    </b-form-checkbox>
                                </b-form-group>
                                <hr />
                                -->
                                <b-form-checkbox
                                    v-for="layer of infoLayers"
                                    :key="layer.name"
                                    :value="true"
                                    v-model="layer.visible"
                                >
                                    <span>&nbsp;{{ layer.label }}</span>
                                </b-form-checkbox>
                            </span>
                        </form>
                    </div>
                </l-control>

                <l-control
                    class="search-control custom-control"
                    position="topleft"
                    v-if="getFocusTruck && !noControls && !minimal"
                >
                    <div id="search-bar" class="d-flex">
                        <div
                            class="search-layers-menu"
                            @click="
                                flyToTruck(getFocusTruck.lastpoint.lat, getFocusTruck.lastpoint.lng)
                            "
                        >
                            <truck-icon :category="getFocusTruck.category"></truck-icon>
                        </div>
                    </div>
                </l-control>

                <l-control v-if="extraControls" position="topleft"></l-control>

                <l-control v-if="extraControls && ($mq == 'xs' || $mq == 'sm')" position="topright">
                    <!-- Extra button for opening vehicles menu on mobile -->
                    <a
                        class="extra-control-button bg-main-bg-color text-main text-main-color"
                        style="margin-bottom: 10px"
                        href="javascript:;"
                        @click="$router.push({ name: 'geofleet.vehicles' })"
                        v-if="extraControls.vehiclesMenu"
                    >
                        <i class="fa-regular fa-lg fa-bars"></i>
                    </a>
                    <!-- Extra button for opening settings menu on mobile -->
                    <a
                        class="extra-control-button bg-main-bg-color text-main text-main-color"
                        href="javascript:;"
                        v-b-modal.modal-mobile-settings
                        v-if="extraControls.mobileSettings"
                    >
                        <i class="fa-regular fa-lg fa-buildings"></i>
                    </a>
                </l-control>

                <l-control-scale
                    :position="$mq == 'xs' ? 'bottomleft' : 'topright'"
                    :imperial="false"
                    :metric="true"
                    v-if="!noControls"
                ></l-control-scale>

                <l-control
                    v-if="extraControls && ($mq == 'xs' || $mq == 'sm')"
                    position="bottomleft"
                >
                    <!-- Extra button for log out user on mobile -->
                    <a
                        class="extra-control-button bg-main-bg-color text-main text-main-color"
                        href="javascript:;"
                        @click="handleLogout"
                        v-if="extraControls.mobileLogout"
                    >
                        <i class="fa-regular fa-lg fa-power-off"></i>
                    </a>
                </l-control>

                <l-control-zoom
                    position="bottomleft"
                    v-if="!noControls && $mq != 'xs' && $mq != 'sm'"
                ></l-control-zoom>
            </l-map>
        </div>

        <b-modal id="modal-mobile-settings" :title="__('company_select')" footer-class="d-none">
            <v-select
                v-model="selectedCompany"
                :options="companyOptions"
                :placeholder="__('company_select')"
                :searchable="true"
                :reduce="(option) => option.value"
                :clearable="false"
                :filterBy="customSearchCompany"
            >
                <template #no-options>
                    {{ __('no_companies_founded') }}
                </template>
            </v-select>
        </b-modal>
    </div>
</template>

<script>
import L from 'leaflet'

import { GestureHandling } from 'leaflet-gesture-handling'
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css'

import { LMap, LTileLayer, LControlZoom, LControl, LControlScale, LGeoJson } from 'vue2-leaflet'
import LControlFullscreen from 'vue2-leaflet-fullscreen'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import { mapState, mapGetters, mapActions } from 'vuex'

import googleAttributionLogo from './../assets/images/google_on_white.png'
import PtvXserver from './../lib/ptv_leaflet.js'

export default {
    name: 'GeofleetMap',
    components: {
        LMap,
        LTileLayer,
        LControlZoom,
        LControl,
        LControlScale,
        LControlFullscreen,
        'l-marker-cluster': Vue2LeafletMarkerCluster,
        LGeoJson
    },
    props: {
        zoom: {
            type: Number,
            default: 5
        },
        center: {
            type: Array,
            default: () => [45, 9]
        },
        advanced: {
            type: Boolean,
            required: false
        },
        cluster: {
            type: Boolean,
            default: true
        },
        map: {
            type: String,
            default: 'osm'
        },
        ptvToken: {
            type: String,
            required: true
        },
        trucks: {
            type: Array,
            default: () => []
        },
        poi: {
            type: Boolean,
            default: false
        },
        menu: {
            type: Boolean,
            default: true
        },
        extraControls: {
            type: Object,
            default: () => ({})
        },
        noControls: {
            type: Boolean,
            default: false
        },
        noAutoCenterMap: {
            type: Boolean,
            default: false
        },
        mode: {
            type: String,
            default: 'geofleet'
        },
        minimal: {
            type: Boolean,
            default: false
        },
        gestureHandling: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localCompanies: [],
            selectedCompany: parseInt(this.$authProcess.getParams('Azienda')) || null,
            localTrucks: [],
            searchBarWindowOpen: false,
            searchBarTrigger: false,
            searchBar: {},
            fullScreen: false,
            lmap: null,
            activeMap: this.map,
            pois: [],
            poisForm: [],
            poiLabels: [
                { value: '', text: this.__('manage_poi.types_poi.all') },
                { value: 1, text: this.__('manage_poi.types_poi.headquarter') },
                { value: 15, text: this.__('manage_poi.types_poi.truck_washes') },
                { value: 12, text: this.__('manage_poi.types_poi.blacklist') },
                { value: 11, text: this.__('manage_poi.types_poi.end_of_the_line') },
                { value: 5, text: this.__('manage_poi.types_poi.customers') },
                { value: 10, text: this.__('manage_poi.types_poi.stops') },
                { value: 14, text: this.__('manage_poi.types_poi.suppliers') },
                { value: 8, text: this.__('manage_poi.types_poi.interports') },
                { value: 9, text: this.__('manage_poi.types_poi.markets') },
                { value: 13, text: this.__('manage_poi.types_poi.large_square') },
                { value: 2, text: this.__('manage_poi.types_poi.poi_distributors') },
                { value: 4, text: this.__('manage_poi.types_poi.poi_workshops') },
                { value: 6, text: this.__('manage_poi.types_poi.harbors') },
                { value: 3, text: this.__('manage_poi.types_poi.restaurants') },
                { value: 7, text: this.__('manage_poi.types_poi.stations') }
            ],
            infoblu_events: {},
            infoblu_alerts: {},

            clusterOpt: {
                maxClusterRadius: this.cluster === true ? 20 : 0,
                showCoverageOnHover: true,
                zoomToBoundsOnClick: true,

                elementsPlacementStrategy: 'clock',

                spiderLegPolylineOptions: {
                    weight: 0
                },
                clockHelpingCircleOptions: {
                    weight: 0.7,
                    opacity: 1,
                    color: 'black',
                    fillOpacity: 1,
                    dashArray: '10 5'
                },
                helpingCircles: true,

                spiderfyDistanceSurplus: 25,
                spiderfyDistanceMultiplier: 1,

                elementsMultiplier: 1.4,
                firstCircleElements: 8,

                spiderfyOnMaxZoom: true,

                animateAddingMarkers: true,

                spiderfiedClassName: 'spiderfied-marker',
                disableClusteringAtZoom: 18,

                chunkedLoading: true
            },
            mapLayers: [
                {
                    name: 'silica',
                    label: this.__('silica'),
                    advanced: true,
                    type: 'ptv_maps_layers',
                    base: false,
                    // attributions: '<a target="_blank" href="https://www.infogestweb.it">IGW</a>',
                    url: 'https://s0{s}-xserver2-europe.cloud.ptvgroup.com/services/rest/XMap/tile/{z}/{x}/{y}?storedProfile={profile}&xtok={token}&layers=labels,transport,background',
                    options: {
                        maxZoom: 22,
                        noWrap: true,
                        subdomains: '1234',
                        token: this.ptvToken,
                        profile: 'silica'
                    }
                },
                {
                    name: 'google_terrain',
                    label: this.__('google'),
                    advanced: true,
                    type: 'google_maps_layers',
                    base: false,
                    attribution:
                        'Map data &copy; <a target="_blank" href="https://www.google.com">Google</a>',
                    attributionLogo: googleAttributionLogo,
                    url: 'https://{s}.google.com/vt/lyrs=m&hl={lang}&x={x}&y={y}&z={z}',
                    options: {
                        maxZoom: 22,
                        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                        lang: this.loggedUserParams?.Lingua.slice(0, 2) || 'it'
                    }
                },
                {
                    name: 'google_satellite',
                    label: this.__('google_satellite'),
                    advanced: true,
                    type: 'google_maps_layers',
                    base: false,
                    attributions:
                        'Map data &copy; <a target="_blank" href="https://www.google.com">Google</a>',
                    attributionLogo: googleAttributionLogo,
                    url: 'https://{s}.google.com/vt/lyrs=y&hl={lang}&x={x}&y={y}&z={z}',
                    options: {
                        maxZoom: 22,
                        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                        lang: this.loggedUserParams?.Lingua.slice(0, 2) || 'it'
                    }
                },
                {
                    name: 'sandbox',
                    label: this.__('sandbox'),
                    advanced: true,
                    type: 'ptv_maps_layers',
                    base: false,
                    // attributions: '<a target="_blank" href="https://www.infogestweb.it">IGW</a>',
                    url: 'https://s0{s}-xserver2-europe.cloud.ptvgroup.com/services/rest/XMap/tile/{z}/{x}/{y}?storedProfile={profile}&xtok={token}&layers=labels,transport,background',
                    options: {
                        maxZoom: 22,
                        noWrap: true,
                        subdomains: '1234',
                        token: this.ptvToken,
                        profile: 'sandbox'
                    }
                },
                {
                    name: 'silkysand',
                    label: this.__('silkysand'),
                    advanced: true,
                    type: 'ptv_maps_layers',
                    base: false,
                    // attributions: '<a target="_blank" href="https://www.infogestweb.it">IGW</a>',
                    url: 'https://s0{s}-xserver2-europe.cloud.ptvgroup.com/services/rest/XMap/tile/{z}/{x}/{y}?storedProfile={profile}&xtok={token}&layers=labels,transport,background',
                    options: {
                        maxZoom: 22,
                        noWrap: true,
                        subdomains: '1234',
                        token: this.ptvToken,
                        profile: 'silkysand'
                    }
                },
                {
                    name: 'gravelpit',
                    label: this.__('gravelpit'),
                    advanced: true,
                    type: 'ptv_maps_layers',
                    base: false,
                    // attributions: '<a target="_blank" href="https://www.infogestweb.it">IGW</a>',
                    url: 'https://s0{s}-xserver2-europe.cloud.ptvgroup.com/services/rest/XMap/tile/{z}/{x}/{y}?storedProfile={profile}&xtok={token}&layers=labels,transport,background',
                    options: {
                        maxZoom: 22,
                        noWrap: true,
                        subdomains: '1234',
                        token: this.ptvToken,
                        profile: 'gravelpit'
                    }
                },
                {
                    name: 'osm',
                    label: this.__('open_street_maps'),
                    advanced: true,
                    base: true,
                    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                    attribution:
                        'Map data &copy; <a target="_blank" href="https://www.openstreetmap.org/">OpenStreetMap</a>',
                    options: {
                        maxZoom: 19
                    }
                },
                {
                    name: 'arcgis',
                    label: this.__('arcgis_satellite'),
                    advanced: false,
                    base: true,
                    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                    attribution:
                        'Map data &copy; <a href="https://www.arcgisonline.com/">ArcGIS</a>',
                    options: {
                        maxZoom: 22
                    }
                }
            ],
            infoLayers: [
                {
                    name: 'traffic',
                    label: this.__('traffic'),
                    visible: false,
                    xserver: true,
                    url: 'https://s0{s}-xserver2-europe.cloud.ptvgroup.com/services/rest/XMap/tile/{z}/{x}/{y}?layers=PTV_TruckSpeedPatterns,PTV_TrafficIncidents&timeConsideration=SNAPSHOT&referenceTime={time}&showOnlyRelevantByTime=false&userLanguage={lang}&drawIcons=true&featureRenderingInformation={featureRenderingInformation}&xtok={token}&contentType=JSON',
                    attribution: '',
                    options: {
                        maxZoom: 22,
                        pane: 'overlayPane',
                        featureRenderingInformation: true,
                        subdomains: '1234',
                        token: this.ptvToken,
                        time: this.moment().toISOString(),
                        lang: this.loggedUserParams?.Lingua || 'it-IT'
                    }
                },
                {
                    name: 'heavy_truck',
                    label: this.__('heavy_vehicles_ban'),
                    visible: false,
                    xserver: true,
                    url: 'https://s0{s}-xserver2-europe.cloud.ptvgroup.com/services/rest/XMap/tile/{z}/{x}/{y}?layers=PTV_RestrictionZones,PTV_TruckAttributes&timeConsideration=SNAPSHOT&referenceTime={time}&showOnlyRelevantByTime=false&userLanguage={lang}&drawIcons=true&featureRenderingInformation={featureRenderingInformation}&xtok={token}&contentType=JSON',
                    attribution: '',
                    options: {
                        maxZoom: 22,
                        pane: 'overlayPane',
                        featureRenderingInformation: true,
                        subdomains: '1234',
                        token: this.ptvToken,
                        time: this.moment().toISOString(),
                        lang: this.loggedUserParams?.Lingua || 'it-IT'
                    }
                }
            ],
            infoblu: {
                options: {
                    events: {
                        onEachFeature: function (feature, layer) {
                            layer.bindPopup(feature.properties.description)
                        },
                        pointToLayer: function (feature, latlng) {
                            return L.marker(latlng, {
                                icon: L.divIcon({
                                    html: '<svg id="Livello_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.27 14.99"><defs><style>.cls-1{fill:#fff;}.cls-2{fill:#244484;}</style></defs><g id="a"><path class="cls-2" d="M11.27,5.64c0,2.57-3.43,7.14-4.94,9.02-.36,.45-1.03,.45-1.39,0C3.41,12.76,0,8.21,0,5.64,0,2.52,2.52,0,5.64,0s5.63,2.52,5.63,5.64Z"/><path class="cls-1" d="M4.64,3.39c-.6-.06-1.05-.47-1.01-.91,.05-.44,.57-.74,1.18-.68,.6,.06,1.05,.47,1.01,.91-.05,.44-.57,.74-1.18,.68Zm-.65,4.22c.37-.23,.79-.3,1.22-.27,.43,.03,.86,.1,1.3,.14,.23,.02,.46,.03,.68,0,.73-.11,1.23-.52,1.52-1.2,.06-.15,.1-.32,.15-.48l.04-.02v-.17s-.02-.02-.02-.02c-.08-.17-.24-.2-.37-.06-.04,.03-.07,.07-.1,.11-.11,.13-.22,.27-.34,.39-.16,.16-.36,.26-.59,.25-.28-.01-.47-.16-.56-.42-.1-.28-.2-.57-.3-.85-.13-.35-.37-.59-.73-.69-.13-.03-.27-.06-.4-.07-.51-.05-1.01,.03-1.5,.19-.39,.13-.76,.3-1.05,.61-.06,.06-.11,.14-.15,.21-.05,.09,0,.17,.1,.17,.06,0,.11-.02,.16-.04,.16-.08,.32-.17,.48-.25,.15-.08,.32-.13,.5-.13,.25,0,.41,.21,.32,.44-.04,.11-.11,.22-.19,.3-.25,.23-.5,.45-.76,.66-.29,.24-.56,.49-.77,.8-.28,.41-.24,.85,.1,1.21,.26,.28,.59,.41,.97,.43,.19,.01,.37-.01,.53-.12,.04-.03,.08-.08,.08-.13,0-.02-.05-.06-.09-.08-.08-.03-.16-.05-.24-.08-.33-.14-.39-.5-.12-.73,.04-.04,.08-.07,.13-.1Z"/></g></svg>',
                                    className: '',
                                    iconSize: [26, 34],
                                    iconAnchor: [13, 34]
                                })
                            })
                        }
                    },
                    alerts: {
                        onEachFeature: function (feature, layer) {
                            layer.bindPopup(feature.properties.road_name)
                        },
                        pointToLayer: function (feature, latlng) {
                            let palette = ['#72BA53', '#F1CD4E', '#E9B361', '#F8403E', '#990000']

                            return L.marker(latlng, {
                                icon: L.divIcon({
                                    html:
                                        '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="a" x="0px" y="0px" viewBox="0 0 11.3 15" style="enable-background:new 0 0 11.3 15;" xml:space="preserve"> <path fill="' +
                                        palette[feature.properties.severity] +
                                        '" stroke="#fff" stroke-width="0.5" d="M5.6,0C2.5,0,0,2.5,0,5.6c0,2.6,3.4,7.1,4.9,9c0.4,0.4,1,0.4,1.4,0c1.5-1.9,4.9-6.4,4.9-9C11.3,2.5,8.8,0,5.6,0 z"/> <text transform="matrix(1 0 0 1 3 7.33)" fill="#FFFFFF" font-size="5px">' +
                                        feature.properties.speed +
                                        '</text> </svg>',
                                    className: '',
                                    iconSize: [26, 34],
                                    iconAnchor: [13, 34]
                                })
                            })
                        }
                    }
                },
                fields: [
                    {
                        name: 'alerts',
                        label: this.__('Allerte traffico'),
                        codes: [],
                        selected: false
                    },
                    {
                        name: 'services',
                        label: this.__('Servizi'),
                        codes: [
                            '009',
                            '001',
                            '006',
                            'SAB',
                            'SFC',
                            'SFO',
                            'SRC',
                            'SRX',
                            '092',
                            '093',
                            '003'
                        ],
                        selected: false
                    },
                    {
                        name: 'atmospheric',
                        label: this.__('Eventi Naturali'),
                        codes: [
                            '009',
                            '002',
                            '013',
                            '053',
                            '027',
                            '037',
                            '044',
                            '052',
                            '054',
                            'BLI',
                            'CVS',
                            'FLD',
                            'FLF',
                            'FOD',
                            'FOF',
                            'FOG',
                            'FOP',
                            'FOX',
                            'FRO',
                            'GAL',
                            'HAD',
                            'HAI',
                            'IBU',
                            'IBV',
                            'ICP',
                            'RAF',
                            'RAH',
                            'RAI',
                            'RIC',
                            'RWI',
                            'SAL',
                            'SFH',
                            'SFL',
                            'SN',
                            'SLT',
                            'SMO',
                            'SRO',
                            'THU',
                            'WIC',
                            'WIG',
                            'WIS',
                            'WIX',
                            'WRM',
                            '046',
                            'LSL',
                            'MUD',
                            'FIR',
                            'PID'
                        ],
                        selected: false
                    },
                    {
                        name: 'events',
                        label: this.__('Eventi'),
                        codes: [
                            '009',
                            '004',
                            '010',
                            '012',
                            '011',
                            '031',
                            '032',
                            '039',
                            '045',
                            '095',
                            'OHX',
                            'OIL',
                            'EVM'
                        ],
                        selected: false
                    },
                    {
                        name: 'traffic',
                        label: this.__('Traffico'),
                        codes: [
                            '009',
                            '022',
                            '026',
                            '028',
                            '033',
                            '035',
                            '036',
                            '049',
                            '094',
                            '097',
                            '098',
                            'ANM',
                            'CCB',
                            'CCC',
                            'HLT',
                            'LCE',
                            'LCP',
                            'LCR',
                            'LCS',
                            'LO1',
                            'LS1',
                            'LS2',
                            'LS3',
                            'LS4',
                            'LS5',
                            'LS6',
                            'LTH',
                            'LTM',
                            'PHT',
                            'RAD',
                            'RCB',
                            'RCD',
                            'RCE',
                            'RCR',
                            'RCT',
                            'REB',
                            'REC',
                            'REO',
                            'RET',
                            'REX',
                            'RIN',
                            'RMP',
                            'RSB',
                            'RXB',
                            'RXC',
                            'RXD',
                            'RXO',
                            'TAL',
                            'SVH',
                            'TBU',
                            'TCN',
                            'TCX',
                            'TEA',
                            'TGL',
                            'TGW',
                            'TUC',
                            'VCC',
                            'VWC',
                            'UOB'
                        ],
                        selected: false
                    },
                    {
                        name: 'accidents',
                        label: this.__('Incidenti'),
                        codes: [
                            '009',
                            '043',
                            'ACB',
                            'ACE',
                            'ACF',
                            'ACH',
                            'ACI',
                            'ACM',
                            'ACS',
                            'ACX',
                            'ACZ',
                            'ADI',
                            'AJA',
                            'AJC',
                            'AJT',
                            'ALL',
                            'AOL',
                            'AOV',
                            'ASP',
                            'BKD',
                            'ETN',
                            'VFR'
                        ],
                        selected: false
                    },
                    {
                        name: 'work',
                        label: this.__('Lavori'),
                        codes: [
                            '009',
                            'RCW',
                            '096',
                            'RMK',
                            'RMM',
                            'RMV',
                            'RMW',
                            'RPC',
                            'RRW',
                            'RWK',
                            'RWL',
                            'RWM',
                            'RWR'
                        ],
                        selected: false
                    }
                ]
            }
        }
    },
    computed: {
        companyOptions() {
            let options = []

            this.localCompanies.map((company) => {
                options.push({
                    value: company.id,
                    label: company.business_name
                })
            })

            return options
        },
        filteredMapLayers() {
            return this.mapLayers.filter((l) => {
                if (l.type == 'google_maps_layers') return this.$can('use', 'google_maps_layers')

                if (l.type == 'ptv_maps_layers') return this.$can('use', 'ptv_maps_layers')

                return true
            })
        },
        attributionLogo() {
            let layerInfo = this.mapLayers.find((l) => l.name == this.activeMap)

            if (layerInfo && layerInfo.attributionLogo) {
                return layerInfo.attributionLogo
            } else {
                return null
            }
        },
        poiTypes() {
            return this.pois
                .map((p) => {
                    return {
                        label: p.type_description,
                        code: p.type
                    }
                })
                .reduce((c, p) => {
                    if (!c.find((cp) => cp.code == p.code)) c.push(p)

                    return c
                }, [])
        },
        infobluEvents() {
            return {
                type: 'FeatureCollection',
                features: this.infoblu_events.features
                    ? this.infoblu_events.features.filter((f) => f.show)
                    : {}
            }
        },
        infobluAlerts() {
            let i = this.infoblu.fields.find((i) => i.name == 'alerts')

            return i && i.selected
        },
        ...mapState({
            config: (state) => state.geoFleet.config,
            storeTrucks: (state) => state.geoFleet.trucks,
            loggedUserParams: (state) => state.loggedUser.params,
            mainMapStopAutoFitBounds: (state) => state.geoFleet.mainMapStopAutoFitBounds
        }),
        ...mapGetters({
            getFocusTruck: 'getFocusTruck'
        })
    },
    methods: {
        customSearchCompany(option, label, search) {
            var content = label.replace(/[#_ .\-/,;:]/g, '') // tolgo i caratteri #_ .-;,:
            var txt = search.replace(/[#_ .\-/,;:]/g, '')
            var re = new RegExp(txt, 'i')
            if (content.match(re)) {
                return true
            }
            return false
        },
        getCompanies() {
            this.$fetch(this.$env.GOLIA_API + '/companies?include[]=permissions')
                .then((res) => {
                    return res.json()
                })
                .then((data) => {
                    this.localCompanies = data.data.permissions
                })
        },
        changeCompany() {
            this.$fetch(this.$env.GOLIA_API + '/companies/change', {
                method: 'POST',
                body: JSON.stringify({
                    azienda: this.selectedCompany
                })
            }).then((response) => {
                if (response.status === 200) {
                    this.$store.commit('updateLoggedUserParams', {})
                    this.$store.commit('updateRulesLoaded', false)

                    setTimeout(() => {
                        window.location.reload()
                    }, 1000)
                }
            })
        },
        flyToTruck(lat, lng) {
            this.lmap.flyTo([lat, lng], 16, { duration: 1 })
        },
        emitReadyMap() {
            this.$emit('readyMap')
        },
        tileLayerFunction(layer) {
            return layer.xserver ? L.tileLayer.xserver : L.tileLayer
        },
        handleFocusTruck(truck) {
            // Per la versione mobile il focus viene gestito solo sullo store perchè non vi è il componente padre su cui emettere l'evento
            // Inoltre, se siamo in mobile, viene aperta la pagina di dettaglio del veicolo
            if ((this.$mq == 'xs' || this.$mq == 'sm') && this.mode != 'shared-link') {
                this.$store.commit('setFocusTruck', { truck: truck, on_focus: true })

                this.$router.push({ name: 'geofleet.vehicle_details', params: { id: truck.plate } })
            }

            this.$emit('focus', truck)
        },
        truckFocus(truck) {
            let current_zoom = this.lmap?.getZoom()

            this.lmap.setView(
                [
                    truck.lastpoint.lat,
                    truck.lastpoint.lng + (this.$mq === 'xs' || this.$mq === 'sm' ? 0 : 0.0015)
                ],
                current_zoom >= 18 ? current_zoom : 18
            )
        },
        centerMap(lat, lng) {
            if (!this.noAutoCenterMap) {
                this.lmap.flyTo([lat, lng + (this.$mq === 'xs' || this.$mq === 'sm' ? 0 : 0.0015)])
                this.$emit('updateFocusMarker', lat, lng)
            }
        },
        getTranslatedDescription(type) {
            return this.poiLabels.find((poi) => poi.value == type).text
        },
        loadPois() {
            this.$fetch(this.$env.GOLIA_API + '/poi')
                .then((res) => {
                    return res.json()
                })
                .then((res) => {
                    this.pois = res.data.map((p) => {
                        p.latLng = [p.lat, p.lng]
                        p.visible = false

                        return p
                    })

                    this.$emit('poisLoaded', this.pois)

                    this.poisForm = this.pois
                        .map((p) => {
                            let exist = null

                            if (this.config && this.config.poisForm)
                                exist = this.config.poisForm.find((f) => f.id == p.type)

                            return {
                                label: this.getTranslatedDescription(p.type),
                                id: p.type,
                                selected: exist ? exist.selected : false
                            }
                        })
                        .reduce((c, p) => {
                            if (!c.find((cp) => cp.id == p.id)) c.push(p)

                            return c
                        }, [])
                })
        },
        loadInfoblu() {
            this.$fetch('https://infoblu.goliaweb.it/events')
                .then((res) => {
                    return res.json()
                })
                .then((res) => {
                    res.features = res.features.map((f) => {
                        f.show = false

                        return f
                    })

                    this.infoblu_events = res
                })

            this.$fetch('https://infoblu.goliaweb.it/alerts')
                .then((res) => {
                    return res.json()
                })
                .then((res) => {
                    res.features = res.features.filter((f) => f.properties.severity >= 2)

                    this.infoblu_alerts = res
                })
        },
        infobluIcon(category) {
            switch (category) {
                case 'alerts':
                    return 'fak fa-fw fa-allerta-traffico'
                case 'services':
                    return 'fak fa-fw fa-servizi'
                case 'atmospheric':
                    return 'fak fa-fw fa-eventi-naturali'
                case 'events':
                    return 'fak fa-fw fa-eventi'
                case 'traffic':
                    return 'fak fa-fw fa-traffic'
                case 'accidents':
                    return 'fak fa-fw fa-incidenti'
                case 'work':
                    return 'fak fa-fw fa-lavori'
                default:
                    return 'fak fa-fw fa-infoblu'
            }
        },
        checkIfMapLayerExists() {
            let current_layer = this.filteredMapLayers.find((l) => l.name == this.activeMap)

            if (!current_layer) this.activeMap = this.filteredMapLayers.at(0).name
        },
        handleLogout() {
            this.logout({ vueInstance: this })
        },
        ...mapActions({
            logout: 'logout'
        })
    },
    watch: {
        selectedCompany: function (val) {
            if (val) this.changeCompany()
        },
        config: {
            handler(newConfig) {
                if (newConfig) {
                    if (newConfig.activeMap) this.activeMap = newConfig.activeMap

                    if (newConfig.poisForm) this.poisForm = newConfig.poisForm

                    if (newConfig.infoLayers) {
                        newConfig.infoLayers.forEach((l) => {
                            let layer = this.infoLayers.find((il) => il.name == l.name)

                            if (layer) layer.visible = l.visible
                        })
                    }

                    if (
                        newConfig.activeVehicleClustering !== null &&
                        newConfig.activeVehicleClustering !== undefined
                    ) {
                        this.clusterOpt.maxClusterRadius = newConfig.activeVehicleClustering
                            ? 20
                            : 0
                    }
                }
            },
            deep: true
        },
        searchBar: {
            handler: function (val) {
                if (
                    !val ||
                    (val.referenceCoordinate.y === null && val.referenceCoordinate.x === null)
                ) {
                    this.$store.commit('setMainMapStopAutocenter', false)
                    this.$store.dispatch('removeMarker', { ref: 'searched-map' })

                    return
                }

                const { y: lat, x: lng } = val.referenceCoordinate

                if (val.referenceCoordinate) {
                    this.lmap.setView([lat, lng], 16)

                    // Draw a marker on searched location
                    if (lat && lng)
                        this.$store.dispatch('drawMarker', {
                            ref: 'searched-map',
                            center: [lat, lng],
                            title: val.label
                        })
                    // Stop auto center map
                    this.$store.commit('setMainMapStopAutocenter', true)
                }
            },
            deep: true
        },
        fullScreen(val) {
            this.$emit('fullScreenToggle', val)
        },
        activeMap() {
            this.checkIfMapLayerExists()

            this.$store.dispatch('saveConfig', { activeMap: this.activeMap, vueInstance: this })
        },
        infoLayers: {
            handler: function (newVal) {
                // Preparing a reduced version of infoLayers to be saved in config
                let infoLayers = newVal.map((l) => {
                    return {
                        name: l.name,
                        visible: l.visible
                    }
                })

                this.$store.dispatch('saveConfig', { infoLayers: infoLayers, vueInstance: this })
            },
            deep: true
        },
        localTrucks(newOne, oldOne) {
            // ACTIVITY PANELS
            if (this.localTrucks.length == 1) {
                if (this.$refs.map) {
                    this.lmap = this.$refs.map.mapObject
                }

                let current_zoom = this.lmap?.getZoom()

                if (this.noAutoCenterMap) {
                    if (newOne[0]?.id != oldOne[0]?.id) {
                        this.lmap.setView(
                            [this.localTrucks[0].lastpoint.lat, this.localTrucks[0].lastpoint.lng],
                            current_zoom > 13 ? current_zoom : 13
                        )
                    }
                } else {
                    this.lmap.setView(
                        [this.localTrucks[0].lastpoint.lat, this.localTrucks[0].lastpoint.lng],
                        current_zoom > 13 ? current_zoom : 13
                    )
                }
            }

            // GLOBAL MAP
            if (
                newOne.length > 1 &&
                newOne.length > oldOne.length &&
                this.mode != 'shared-link' &&
                !this.mainMapStopAutoFitBounds
            ) {
                let bounds = L.latLngBounds(
                    this.localTrucks.map((t) => [t.lastpoint.lat, t.lastpoint.lng])
                )

                if (this.$refs.map) {
                    this.lmap = this.$refs.map.mapObject
                    this.lmap.fitBounds(bounds.pad(this.$mq == 'xs' ? 0 : 0.5))
                }
            }
        },
        trucks(val) {
            this.localTrucks = val
        },
        storeTrucks(val) {
            if (!this.trucks || this.trucks.length == 0) this.localTrucks = val
        },
        poisForm: {
            handler(newP) {
                this.$store.dispatch('saveConfig', { poisForm: newP, vueInstance: this })

                for (var p of newP) {
                    this.pois.forEach((poi) => {
                        if (poi.type == p.id) poi.visible = p.selected
                    })
                }
            },
            deep: true
        },
        infoblu: {
            handler() {
                let codes = []

                for (var i of this.infoblu.fields) {
                    if (i.selected) codes.push(...i.codes)
                }

                for (var e of this.infoblu_events.features || []) {
                    if (codes.includes(e.properties.type_code)) {
                        e.show = true
                    } else {
                        e.show = false
                    }
                }
            },
            deep: true
        }
    },
    created() {
        this.checkIfMapLayerExists()

        if (this.poi) this.loadPois()

        // if (this.advanced)
        //     this.loadInfoblu()

        if (this.trucks && this.trucks.length > 0) {
            this.localTrucks = this.trucks
        } else {
            this.localTrucks = this.storeTrucks
        }

        this.getCompanies()

        if (this.gestureHandling)
            L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling)

        PtvXserver(L, this.__, 'ptv.')
    },
    mounted() {
        this.$nextTick(() => {
            this.lmap = this.$refs.map.mapObject
        })
    }
}
</script>

<style scoped lang="scss">
.attribution-logo {
    position: absolute;
    bottom: 10px;
    left: 70px;
    z-index: 1000;
}

.geofleet-map {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
}
l-map {
    width: 100vw;
    height: 100px;
}

/* CONTROLS */
.geofleet-map::v-deep(.leaflet-control-container) {
    --control-buttons-width: 44px;
    --smaller-control-buttons-width: calc(var(--control-buttons-width) - 4px);

    .leaflet-control-fullscreen,
    .leaflet-control.custom-control {
        background: var(--main-bg-color);
        border: 2px solid rgba(0, 0, 0, 0.2);
        color: var(--main-text-color);
    }

    .leaflet-control-fullscreen,
    .extra-control-button {
        width: var(--control-buttons-width);
        height: var(--control-buttons-width);
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        box-shadow: none;
        border-radius: 4px;
        font-size: 16px;
        text-decoration: none;
    }
    .search-control {
        justify-content: flex-start;
    }

    .map-layers-menu {
        width: calc(var(--control-buttons-width) - 8px);
        height: calc(var(--control-buttons-width) - 8px);
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAQAAAADQ4RFAAACf0lEQVR4AY1UM3gkARTePdvdoTxXKc+qTl3aU5U6b2Kbkz3Gtq3Zw6ziLGNPzrYx7946Tr6/ee/XeCQ4D3ykPtL5tHno4n0d/h3+xfuWHGLX81cn7r0iTNzjr7LrlxCqPtkbTQEHeqOrTy4Yyt3VCi/IOB0v7rVC7q45Q3Gr5K6jt+3Gl5nCoDD4MtO+j96Wu8atmhGqcNGHObuf8OM/x3AMx38+4Z2sPqzCxRFK2aF2e5Jol56XTLyggAMTL56XOMoS1W4pOyjUcGGQdZxU6qRh7B9Zp+PfpOFlqt0zyDZckPi1ttmIp03jX8gyJ8a/PG2yutpS/Vol7peZIbZcKBAEEheEIAgFbDkz5H6Zrkm2hVWGiXKiF4Ycw0RWKdtC16Q7qe3X4iOMxruonzegJzWaXFrU9utOSsLUmrc0YjeWYjCW4PDMADElpJSSQ0vQvA1Tm6/JlKnqFs1EGyZiFCqnRZTEJJJiKRYzVYzJck2Rm6P4iH+cmSY0YzimYa8l0EtTODFWhcMIMVqdsI2uiTvKmTisIDHJ3od5GILVhBCarCfVRmo4uTjkhrhzkiBV7SsaqS+TzrzM1qpGGUFt28pIySQHR6h7F6KSwGWm97ay+Z+ZqMcEjEWebE7wxCSQwpkhJqoZA5ivCdZDjJepuJ9IQjGGUmuXJdBFUygxVqVsxFsLMbDe8ZbDYVCGKxs+W080max1hFCarCfV+C1KATwcnvE9gRRuMP2prdbWGowm1KB1y+zwMMENkM755cJ2yPDtqhTI6ED1M/82yIDtC/4j4BijjeObflpO9I9MwXTCsSX8jWAFeHr05WoLTJ5G8IQVS/7vwR6ohirYM7f6HzYpogfS3R2OAAAAAElFTkSuQmCC');
        background-position: 50% 50%;
        background-repeat: no-repeat;
        display: block;
    }

    #search-bar {
        .search-layers-menu {
            width: var(--control-buttons-width);
            height: var(--control-buttons-width);
            display: flex;
            align-content: center;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            cursor: pointer;
        }

        .search-bar-input {
            .v-select.vs--single {
                background: var(--main-bg-color);
                .vs__dropdown-toggle {
                    background: var(--main-bg-color);
                    color: var(--main-text-color);
                    font-size: 11px;
                    font-family: var(--main-font);
                    height: var(--control-buttons-width);
                    border: none;
                    overflow: hidden;
                }
            }
        }
    }
    .leaflet-control-simpleMapScreenshoter {
        border-radius: 4px;
    }
    .leaflet-control-simpleMapScreenshoter a {
        width: var(--smaller-control-buttons-width);
        height: var(--smaller-control-buttons-width);
    }

    .leaflet-bar {
        box-shadow: none;
    }

    .leaflet-control-zoom.leaflet-bar {
        a {
            background: var(--main-bg-color);
            color: var(--main-text-color);
        }
    }
    .leaflet-control-zoom-in,
    .leaflet-control-zoom-out {
        width: var(--smaller-control-buttons-width);
        height: var(--smaller-control-buttons-width);
        line-height: var(--smaller-control-buttons-width);
    }
}

.geofleet-map.smaller-controls::v-deep(.leaflet-control-container) {
    --control-buttons-width: 36px;

    .map-layers-menu {
        background-size: 70%;
    }
    #search-bar .search-layers-menu {
        width: var(--smaller-control-buttons-width);
        height: var(--smaller-control-buttons-width);
        font-size: 14px;
    }
}

#layers_form {
    font-size: 14px;
    background-color: var(--main-bg-color);
    color: var(--main-text-color);
    padding: 12px;
    border-radius: 4px;
    display: none;
}
#layers_form hr {
    margin: 10px;
}
.map-layers-button {
    background-color: var(--main-bg-color);
    display: block;
    border-radius: 4px;
    border: 2px solid var(--main-bg-color);
    overflow-y: auto;
}
.map-layers-button:hover .map-layers-menu {
    display: none;
}
.map-layers-button:hover #layers_form {
    display: block;
}
</style>

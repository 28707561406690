import mutations from './geofleet.mutations'
import actions from './geofleet.actions'
import { buildVehicleAlerts } from '@/functions/checkVehicleAlerts'

const state = () => ({
    trucks: [],
    trucksRef: [],
    truckFilteredOut: [],
    trucksEvents: [],
    trucksLastEventId: 14,
    drivers: {},
    driversData: {},
    driversPlafondData: {},
    companies: {},
    pois: [],
    mainMapStopAutocenter: false,
    mainMapStopAutoFitBounds: false,
    mainMapInstance: null,
    mainMapExtraObjects: {
        markers: [],
        circles: [],
        polygons: [],
        lines: []
    },
    gmaps: null,
    companyTags: [],
    filtersDictionary: {
        parkings: [],
        stations: []
    },
    config: {},
    vehiclesCrossSearch: {},
    vehiclesCrossResults: [],
    bluetooth_devices: []
})

const getters = {
    getFocusTruck: (state) => {
        return state.trucks.find((t) => t.on_focus) || null
    },
    getActivityTruck: (state) => {
        return state.trucks.find((t) => t.on_activity) || null
    },
    getArrayOfDriversData: (state) => {
        var tmpArray = []

        for (const [key, value] of Object.entries(state.driversData)) {
            value.id = key
            tmpArray.push(value)
        }

        return tmpArray
    },
    getTrucksEventsSinceLastEventId: (state) => {
        if (state.trucksLastEventId <= 1) return []

        return state.trucksEvents.filter((event) => event.id > state.trucksLastEventId)
    },
    getTrucksWithAlerts: (state) => {
        let trucksWithAlerts = []

        state.trucks.forEach((truck) => {
            let alerts = buildVehicleAlerts(truck)

            if (Object.entries(alerts).length > 0) {
                trucksWithAlerts.push({
                    ...truck,
                    alerts: alerts
                })
            }
        })

        return trucksWithAlerts
    },
    getBluetoothDevicesByVehicle: (state) => {
        const devicesByVehicle = {}
        state.bluetooth_devices.forEach((device) => {
            if (!devicesByVehicle[device.vehicle]) {
                devicesByVehicle[device.vehicle] = []
            }
            devicesByVehicle[device.vehicle].push(device)
        })
        return devicesByVehicle
    },
    getAppActions: (state) => {
        return {
            filtersActive: state.truckFilteredOut.length > 0,
            mainMapStopAutocenter: state.mainMapStopAutocenter,
            mainMapStopAutoFitBounds: state.mainMapStopAutoFitBounds
        }
    },
    getDriversPlafondData: (state) => {
        return state.driversPlafondData
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}

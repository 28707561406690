var render = function render(){var _vm=this,_c=_vm._self._c;return _c('l-marker',{attrs:{"visible":_vm.truck.visible,"lat-lng":[_vm.truck.lastpoint.lat, _vm.truck.lastpoint.lng]},on:{"click":function($event){return _vm.$emit('click', _vm.truck)}}},[_c('l-icon',{attrs:{"icon-anchor":_vm.anchorSize,"icon-size":_vm.iconSize,"class-name":"position-relative"}},[_c('svg',{class:{ bounce: _vm.bounce, focused: _vm.truck.on_focus },attrs:{"id":"Goccia","xmlns":"http://www.w3.org/2000/svg","viewBox":"-19 -3 159.5 190.4"}},[_c('path',{attrs:{"id":"Traccia_goccia","data-name":"Traccia goccia","d":"M164,84.8c0,33-59.7,109.2-59.7,109.2S44.5,117.8,44.5,84.8a59.8,59.8,0,0,1,119.5,0Z","transform":"translate(-44.5 -12.6)","fill":"#fff"}}),_c('path',{staticStyle:{"filter":"brightness(1.2)"},attrs:{"id":"Base_goccia","data-name":"Base goccia","d":"M157.4,87.6c0,29.3-53.1,97-53.1,97s-53.1-67.7-53.1-97a53.1,53.1,0,0,1,106.2,0Z","transform":"translate(-44.5 -12.6)","fill":_vm.iconBackgroundColor}}),_c('path',{staticStyle:{"filter":"brightness(0.9)"},attrs:{"id":"Mezza_goccia_riflesso","data-name":"Mezza goccia riflesso","d":"M157.4,87.6a53.1,53.1,0,0,0-53.1-53.1V184.6S157.4,116.9,157.4,87.6Z","transform":"translate(-44.5 -12.6)","fill":_vm.iconBackgroundColor}}),_c('g',{staticClass:"rotate",style:({
                    transform: _vm.direction,
                    'transform-origin': '37.7% 39%',
                    '-webkit-transform-Origin': '37.7% 39%',
                    '-moz-transform-Origin': '37.7% 39%',
                    '-ms-transform-Origin': '37.7% 39%',
                    '-o-transform-Origin': '37.7% 39%'
                }),attrs:{"fill":"div-rotate"}},[(_vm.vehicleAlertsList.length > 0)?_c('path',{attrs:{"id":"Freccia_direzionale","data-name":"Freccia direzionale","d":"M150.1,86.4a45.9,45.9,0,0,0-30.2-43.1L104.3,12.6,88.7,43.3a45.8,45.8,0,1,0,61.4,43.1Z","transform":"translate(-44.5 -12.6)","fill":"var(--danger)"}}):_c('path',{class:_vm.vehicle_status,staticStyle:{"filter":"brightness(0.6)"},attrs:{"id":"Freccia_direzionale","data-name":"Freccia direzionale","d":"M150.1,86.4a45.9,45.9,0,0,0-30.2-43.1L104.3,12.6,88.7,43.3a45.8,45.8,0,1,0,61.4,43.1Z","transform":"translate(-44.5 -12.6)","fill":_vm.iconBackgroundColor}})]),(_vm.vehicleAlertsList.length > 0)?_c('text',{staticStyle:{"font-family":"'Font Awesome Kit', 'FontAwesome'","font-size":"52px"},attrs:{"x":"35","y":"90","fill":"#fff"},domProps:{"innerHTML":_vm._s('&#xF071;')}}):_c('text',{staticStyle:{"font-family":"'Font Awesome Kit', 'FontAwesome', 'Font Awesome 6 Pro'","font-size":"50px"},attrs:{"x":_vm.iconPosition.x,"y":_vm.iconPosition.y,"fill":"#fff"},domProps:{"innerHTML":_vm._s(_vm.iconType)}})]),_c('div',{staticClass:"plate d-flex flex-column flex-wrap",class:{ focused: _vm.truck.on_focus },style:(_vm.config.vehicleMarkerFontColor
                    ? '--text-color: ' + _vm.config.vehicleMarkerFontColor
                    : '')},[(_vm.config?.vehicleIdentification && _vm.config?.copyIdentificationToMarker)?_c('span',{class:{ 'with-additional-data': _vm.vehicleAdditionalData },style:(_vm.markerStyle)},[_vm._v(_vm._s(_vm._f("truncate")(_vm.vehicleIdentification,7)))]):_c('span',{class:{ 'with-additional-data': _vm.vehicleAdditionalData },style:(_vm.markerStyle)},[_vm._v(_vm._s(_vm._f("truncate")((_vm.truck.details.custom_id || _vm.truck.plate),7)))]),(_vm.vehicleAdditionalData)?_c('span',{staticStyle:{"border-top-left-radius":"0px","border-top-right-radius":"0px"},style:(_vm.markerAdditionalInfoStyle)},[_vm._v(_vm._s(_vm.vehicleAdditionalData))]):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }